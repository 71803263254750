var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// TODO: remove duplicate cookie library from bundle
import cookies from 'js-cookie';
import topDomain from '@segment/top-domain';
import { EventEmitter } from 'events';
var DEFAULT_COOKIE_NAME = 'tracking-preferences';
var COOKIE_DEFAULT_EXPIRES = 365;
// TODO: harden against invalid cookies
// TODO: harden against different versions of cookies
export function loadPreferences(cookieName) {
    var preferences = cookies.getJSON(cookieName || DEFAULT_COOKIE_NAME);
    if (!preferences) {
        return {};
    }
    return {
        destinationPreferences: preferences.destinations,
        customPreferences: preferences.custom
    };
}
var emitter = new EventEmitter();
/**
 * Subscribes to consent preferences changing over time and returns
 * a cleanup function that can be invoked to remove the instantiated listener.
 *
 * @param listener a function to be invoked when ConsentPreferences are saved
 */
export function onPreferencesSaved(listener) {
    emitter.on('preferencesSaved', listener);
    return function () { return emitter.off('preferencesSaved', listener); };
}
function shouldRemoveCookie(preference, preferences) {
    var _a, _b, _c;
    switch (preference) {
        case 'advertising':
            return ((_a = preferences.customPreferences) === null || _a === void 0 ? void 0 : _a.advertising) === false;
        case 'functional':
            return ((_b = preferences.customPreferences) === null || _b === void 0 ? void 0 : _b.functional) === false;
        case 'marketingAndAnalytics':
            return ((_c = preferences.customPreferences) === null || _c === void 0 ? void 0 : _c.marketingAndAnalytics) === false;
        default:
            return false;
    }
}
export function deleteCookiesOnPreferencesChange(preferences, initial) {
    if (initial === void 0) { initial = false; }
    var wd = window;
    if (!wd.cookieConsentChoices) {
        return;
    }
    var cookiesNames = Object.keys(cookies.get());
    wd.cookieConsentChoices.forEach(function (_a) {
        var preference = _a[0], names = _a[1], domain = _a[2], callback = _a[3];
        if (!preference || !names) {
            return;
        }
        if (!domain) {
            domain = topDomain(window.location.href);
        }
        var shouldReload = false;
        names.forEach(function (name) {
            var cookiesRemoved = 0;
            var matchingNames = cookiesNames.filter(function (cookieName) { return cookieName.startsWith(name); });
            matchingNames.forEach(function (cookieName) {
                if (shouldRemoveCookie(preference, preferences)) {
                    cookies.remove(cookieName, { domain: domain });
                    shouldReload = true;
                    cookiesRemoved++;
                }
            });
            if (callback && cookiesRemoved > 0) {
                callback();
            }
        });
        if (shouldReload && !initial) {
            window.location.reload();
        }
    });
}
function matchDestinationsToCurrentCookieValue(preferences) {
    return {
        'Facebook Pixel': preferences === null || preferences === void 0 ? void 0 : preferences.advertising,
        'Google AdWords New': preferences === null || preferences === void 0 ? void 0 : preferences.advertising,
        Heap: preferences === null || preferences === void 0 ? void 0 : preferences.marketingAndAnalytics
    };
}
export function savePreferences(_a) {
    var destinationPreferences = _a.destinationPreferences, customPreferences = _a.customPreferences, cookieDomain = _a.cookieDomain, cookieName = _a.cookieName, cookieExpires = _a.cookieExpires, _b = _a.cookieAttributes, cookieAttributes = _b === void 0 ? {} : _b;
    var domain = cookieDomain || topDomain(window.location.href);
    var expires = cookieExpires || COOKIE_DEFAULT_EXPIRES;
    var value = {
        version: 1,
        destinations: matchDestinationsToCurrentCookieValue(customPreferences),
        custom: customPreferences
    };
    cookies.set(cookieName || DEFAULT_COOKIE_NAME, value, __assign({ expires: expires,
        domain: domain }, cookieAttributes));
    deleteCookiesOnPreferencesChange({ destinationPreferences: destinationPreferences, customPreferences: customPreferences });
    emitter.emit('preferencesSaved', {
        destinationPreferences: destinationPreferences,
        customPreferences: customPreferences
    });
}
