export var DESTINATIONS = [
    {
        id: 'Heap',
        name: 'Heap',
        description: 'Heap is an analytics tool that automatically tracks all of the actions your users perform just by flipping a switch, instead of after adding custom tracking code.',
        website: 'http://heapanalytics.com',
        category: 'Analytics',
        creationName: 'Heap'
    },
    {
        id: 'Datadog',
        name: 'Datadog',
        description: 'Datadog is a monitoring service for cloud-scale applications, providing monitoring of servers, databases, tools, and services, through a SaaS-based data analytics platform.',
        website: 'http://datadoghq.com',
        category: 'Analytics',
        creationName: 'Datadog'
    },
    {
        id: 'Google Analytics',
        name: 'Google Analytics',
        description: 'Google Analytics is a web analytics service offered by Google that tracks and reports website traffic.',
        website: 'http://google.com/analytics',
        category: 'Advertising',
        creationName: 'Google Analytics'
    },
    {
        id: 'Facebook Pixel',
        name: 'Facebook Pixel',
        description: 'Facebook Pixel is an analytics tool that helps you measure the effectiveness of your advertising.',
        website: 'http://facebook.com',
        category: 'Advertising',
        creationName: 'Facebook Pixel'
    },
    {
        id: 'Google Ads (Gtag)',
        name: 'Google Ads (Gtag)',
        description: 'Google Ads (Gtag) is an advertising service by Google for businesses wanting to display ads on Google and its advertising network.',
        website: 'http://google.com/ads',
        category: 'Advertising',
        creationName: 'Google Ads (Gtag)'
    },
    {
        id: 'Optimizely',
        name: 'Optimizely',
        description: "Optimizely is the world's leading experimentation platform, enabling businesses to deliver continuous experimentation and personalization across websites, mobile apps, and connected devices.",
        website: 'http://optimizely.com',
        category: 'Essential',
        creationName: 'Optimizely'
    },
    {
        id: 'Cloudflare',
        name: 'Cloudflare',
        description: 'Cloudflare is a web-infrastructure and website-security company, providing content-delivery-network services, DDoS mitigation, Internet security, and distributed domain-name-server services.',
        website: 'http://cloudflare.com',
        category: 'Essential',
        creationName: 'Cloudflare'
    }
];
